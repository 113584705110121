// Next js Components
import Link from 'next/link'
import { Formik, Form } from 'formik'
import { useMemo, useCallback, useState } from 'react'
import { GoogleOAuthProvider } from '@react-oauth/google'

import Button from '@/components/base/Button'
import Checkbox from '@/components/base/Checkbox'
import Google from '@/components/login/Google'
import TextBox from '@/components/base/TextBox'
import SeparatorWithTitle from '@/components/base/SeparatorWithTitle'
import Alert from '@/components/base/Alert'

import { useSession } from '@/contexts/Session'
import loginValidation from '@/validations/login'

import styles from '@/styles/Auth.module.css'

const Auth = () => {
    const { setAuthToken } = useSession()

    const initialState = useMemo(
        () => ({
            email: '',
            password: '',
            remember: false,
        }),
        []
    )
    const [googleLoading, setGoogleLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)

    const handleSubmit = useCallback(
        async ({ email, password, remember }, { setErrors, setSubmitting }) => {
            setSubmitting(true)

            const signInUrl = `${process.env.NEXT_PUBLIC_API_URL}/auth/login`

            const fetchOptions = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    password,
                    remember,
                    permission: '',
                }),
            }

            const response = await fetch(signInUrl, fetchOptions)

            const data = await response.json()

            if (response.ok) {
                setAuthToken('accessToken', data?.data?.accessToken, {
                    path: '/',
                    sameSite: true,
                    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
                })
                setAuthToken('refreshToken', data?.data?.refreshToken, {
                    path: '/',
                    sameSite: true,
                    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
                })

                setAuthToken('organizationId', data?.data?.organization?.id, {
                    path: '/',
                    sameSite: true,
                    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
                })

                setAuthToken('logo', data?.data?.organization?.logo, {
                    path: '/',
                    sameSite: true,
                    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
                })

                setAuthToken('projectId', data?.data?.organization?.projectId, {
                    path: '/',
                    sameSite: true,
                    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
                })
                setAuthToken('title', data?.data?.organization?.title, {
                    path: '/',
                    sameSite: true,
                    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
                })
                setAuthToken('isMenuOpen', false, {
                    path: '/',
                    sameSite: true,
                    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
                })
            } else {
                setErrors({
                    email: data.message,
                    password: data.message,
                })
            }

            setSubmitting(false)
        },
        [setAuthToken]
    )

    return (
        <div>
            <h2 className={styles.heading}>
                {`"Log in" since you can't signup from this page`}
            </h2>
            <h3 className={styles.tagline}>
                Welcome back, select method to sign in:
            </h3>
            <div className="mb-4">
                <GoogleOAuthProvider
                    clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}
                >
                    <Google
                        googleLoading={googleLoading}
                        setGoogleLoading={setGoogleLoading}
                        setErrorMessage={setErrorMessage}
                        variant="google"
                    />
                </GoogleOAuthProvider>
            </div>
            <div className="my-6">
                <SeparatorWithTitle title="Or continue with" />
            </div>
            {/* Form  */}
            <div>
                <Formik
                    initialValues={initialState}
                    validationSchema={loginValidation}
                    onSubmit={handleSubmit}
                    autoComplete="off"
                >
                    {({ errors, touched }) => {
                        const firstKey = Object.keys(errors)[0]
                        const showError =
                            (!!errors[firstKey] && !!touched[firstKey]) ||
                            errorMessage
                        return (
                            <Form noValidate>
                                <div className="auth-form-section">
                                    <div>
                                        <TextBox
                                            id="email"
                                            name="email"
                                            type="email"
                                            label="Email address"
                                            placeholder="Email address"
                                        />
                                    </div>
                                    <div>
                                        <TextBox
                                            id="password"
                                            name="password"
                                            type="password"
                                            label="Password"
                                            placeholder="Password"
                                        />
                                    </div>
                                </div>

                                <div className="mb-4 grid grid-cols-2">
                                    <div className="grid grid-cols-1">
                                        <Checkbox
                                            id="remember"
                                            name="remember"
                                            label="Remember me"
                                        />
                                    </div>
                                    <div className="relative top-0 ml-auto text-right">
                                        <Link
                                            href="/password/request"
                                            className="text-sm font-medium text-primary-500 hover:underline"
                                        >
                                            Forgot your password?
                                            <span className="custom-underline"></span>
                                        </Link>
                                    </div>
                                </div>
                                <div>
                                    <Button
                                        text="Sign in"
                                        type="submit"
                                        iconStyle="left-4 absolute"
                                        variant="primary"
                                        modification="w-full"
                                        id="signin_submit"
                                    />
                                </div>
                                {showError && (
                                    <div className="mt-4 text-left">
                                        <Alert
                                            size="sm"
                                            variant="danger"
                                            message={
                                                errors[firstKey] || errorMessage
                                            }
                                            icon="cancel-fill"
                                            iconStyle="text-danger-500 pr-2 text-xs"
                                        ></Alert>
                                    </div>
                                )}
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </div>
    )
}

export default Auth
