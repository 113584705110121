import { useMemo } from 'react'
import { useFormikContext } from 'formik'

import kebabCase from 'lodash/kebabCase'

import styles from '@/styles/CheckboxDefault.module.css'

const Checkbox = ({
    id,
    name,
    label,
    style2 = false,
    disabled,
    checkboxLabelStyle,
}) => {
    const { handleChange, getFieldMeta } = useFormikContext() || {}
    const { value } = getFieldMeta ? getFieldMeta(name) : {}
    const id$ = useMemo(() => id || `${kebabCase(label)}`, [id, label])

    return (
        <div>
            <label
                htmlFor={id$}
                className={`${style2 ? styles.checkStyle2 : styles.checkBox} ${
                    disabled ? styles.disabled : ''
                }`}
            >
                <input
                    id={id$}
                    name={name}
                    type="checkbox"
                    onChange={handleChange}
                    checked={value}
                />
                <span className={styles.checkmark}>
                    <i className="icon-check"></i>
                </span>
                <span
                    className={`text-common-black ${checkboxLabelStyle} ${
                        style2 ? styles.checkBoxLabel : ''
                    }`}
                >
                    {label}
                </span>
            </label>
        </div>
    )
}

export default Checkbox
