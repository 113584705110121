import { useCallback } from 'react'
import { useGoogleLogin } from '@react-oauth/google'

import Button from '@/components/base/Button'

import { useSession } from '@/contexts/Session'

const Google = ({
    googleLoading,
    setGoogleLoading,
    setErrorMessage,
    isCheck,
    variant,
    modification,
}) => {
    const { setAuthToken } = useSession()

    const googleLogin = useGoogleLogin({
        onSuccess: (tokenResponse) => onSuccess(tokenResponse),
        onError: (errorResponse) => onError(errorResponse),
    })

    const onSuccess = useCallback(
        async (tokenResponse) => {
            setGoogleLoading(true)

            const authLoginUrl = `${process.env.NEXT_PUBLIC_API_URL}/auth/google`

            const fetchOptions = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: tokenResponse?.access_token,
                }),
            }

            const response = await fetch(authLoginUrl, fetchOptions)

            const data = await response.json()

            if (response.ok) {
                setAuthToken('accessToken', data?.data?.accessToken, {
                    path: '/',
                    sameSite: true,
                    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
                })
                setAuthToken('refreshToken', data?.data?.refreshToken, {
                    path: '/',
                    sameSite: true,
                    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
                })

                setAuthToken('organizationId', data?.data?.organization?.id, {
                    path: '/',
                    sameSite: true,
                    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
                })

                setAuthToken('logo', data?.data?.organization?.logo, {
                    path: '/',
                    sameSite: true,
                    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
                })

                setAuthToken('projectId', data?.data?.organization?.projectId, {
                    path: '/',
                    sameSite: true,
                    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
                })
                setAuthToken('title', data?.data?.organization?.title, {
                    path: '/',
                    sameSite: true,
                    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
                })
                setAuthToken('isMenuOpen', false, {
                    path: '/',
                    sameSite: true,
                    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
                })
                const timer = setTimeout(() => {
                    setGoogleLoading(false)
                    setErrorMessage(null)
                }, 2000)
                return () => clearTimeout(timer)
            } else {
                setGoogleLoading(false)
                setErrorMessage(data?.message)
            }
        },
        [setGoogleLoading, setAuthToken, setErrorMessage]
    )

    const onError = useCallback(
        async (error) => {
            // eslint-disable-next-line
            console.log('onError', JSON.stringify(error, undefined, 2))
            setGoogleLoading(false)
            setErrorMessage(JSON.stringify(error, undefined, 2))
        },
        [setErrorMessage, setGoogleLoading]
    )

    return (
        <>
            <div className="relative">
                <Button
                    type="button"
                    variant={variant}
                    text="Connect with Google"
                    modification={`w-full pl-[25px] justify-center mb-0 shadow ${modification}`}
                    image="/storage/google.svg"
                    imageModification="absolute left-0"
                    click={() => googleLogin()}
                    loading={googleLoading}
                />
                {isCheck && (
                    <div className="absolute right-[14px] top-[13px] cursor-pointer ipad:top-[10px]">
                        <div className="icon-check-circle text-2xl text-interface-400 ipad:text-xl"></div>
                    </div>
                )}
            </div>
        </>
    )
}

export default Google
